import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/dinny/logoig.svg'


import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
let id = 'ismi-taufik'
let inisial_ce = 'Ismi'
let inisial_co = 'Taufik'

let lengkap_co = (<>Muhammad Taufik</>)
let lengkap_ce = (<>Ismi Puspita Sari</>)

let bapak_co = 'Mr. Yuswendi Surin '
let ibu_co = 'Mrs. Musidah Wendy'
let bapak_ce = "Mr. Sutrisno "
let ibu_ce = "Mrs. Tri Endang Pujiati"

let ig_co = "kipoopik"
let ig_ce = "ismipuspitaa"

let foto_ce = "https://i.ibb.co/WHrgx9M/Individu-2.jpg"
let foto_co = "https://i.ibb.co/wRYZWc4/Individu-1.jpg"
let waktunikah = "02/27/2021"

let modal = "https://i.ibb.co/hVbgSCm/Modal.jpg"
let openlogo = "https://i.ibb.co/BsbhRzr/logo-Nasta.png"

let gmaps = "https://goo.gl/maps/TcLkZ43VUXFoNnBTA"
let gcalendar = 'https://www.google.com/maps/search/Unnamed+Road,+Sengeti,+Sekernan,+Kabupaten+Muaro+Jambi,+Jambi+36381,+Indonesia/@-1.471636,103.5108192,17z?hl=en'

let slide = [
"ISMITAUFIKPREWED-205.jpg",
"ISMITAUFIKPREWED-230.jpg",
"ISMITAUFIKPREWED-233.jpg",
"ISMITAUFIKPREWED-256.jpg",
"ISMITAUFIKPREWED-338.jpg",
"ISMITAUFIKPREWED-417.jpg",
"ISMITAUFIKPREWED-462.jpg",
"ISMITAUFIKPREWED-470.jpg",
"ISMITAUFIKPREWED-479.jpg",
]
    
let cmain = '#fce7d2ff'
let mainfont = '#AD3243'
let csub = " #E3B62D"
let black = 'rgb(38,38,38)'
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 1,
            showgift: false,
            show: false,
            show1: false,
            show2: false
        }
    }
    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date(waktunikah).getTime();
        // Update the count down every 1 second
        var x = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);
    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(pw("ismi", "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "ismi-taufik", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, sesi, show, show1, show2 } = this.state

        let slider = []
        slide.map(v => {
            slider.push(gambar(pw("ismi",v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('name');
        let time = this.useQuery().get('time');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title='Undanganku - Ismi & Taufik'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url='https://undanganku.me/ismi-taufik'
                />

                <div id='gold5' style={{
                    backgroundColor: cmain,
                    backgroundImage: `url(${pw("asset", "mable.jpg")})`,
                    backgroundSize: '100vw 100vh',
                    backgroundAttachment: 'fixed'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${pw("ismi","modal.jpg")}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={pw("ismi", "logo.png")} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        <br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}
                                    className={`col-md-4 button roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                  </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>
                                        <p className="fs24 text-center">
                                            <span className="fs16" style={{ color: 'black' }}>
                                                And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect. <br /><br />(Ar-Rum: 21)
                  </span>
                                        </p>
                                    </Item>
                                    <Container id='sectiongold57'>
                                    <div className='pt-3'>

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak' 
                                                style={{ border:'2px solid black',color:'black',backgroundColor:'transparent' }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div style={{color:'black'}}>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{color:'black'}}>
                                                                Days
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div style={{color:'black'}}>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{color:'black'}}>
                                                                Hours
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div style={{color:'black'}}>
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{color:'black'}}>
                                                                Mins
                                                            </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div style={{color:'black'}}>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span style={{color:'black'}}>
                                                                Secs
                                                            </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("asset", "bunga-rumput.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'black' }}>
                                            The Intimate Wedding of {inisial_co} & {inisial_ce}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >
                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: 'black' }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw("ismi","ce.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '36px', fontFamily: "'Marck Script', cursive",
                                                        color: 'black'
                                                    }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: 'black', fontStyle: 'normal' }}>
                                                        <b>The Daughter of: </b><br />
                                                        {bapak_ce}<br />
                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />
                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{ backgroundImage: `url('https://rbmg.fr/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-9.png')` }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: 'black' }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw("ismi","co.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '36px',
                                                        fontFamily: "'Marck Script', cursive", color: 'black'
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{
                                                        fontSize: '20px', color: 'black',
                                                        fontStyle: 'normal'
                                                    }}>
                                                        <b>The Son of : </b><br />
                                                        {bapak_co}
                                                        <br />
                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>

                                <Container className="p-3" style={{ backgroundColor: 'white', color: 'black', borderRadius: '15px', fontFamily: 'Tinos, serif' }}>
                                    <h1 className="w-100 text-center">
                                        Akad
                    </h1>
                                    <p className="w-100 text-center">
                                        27th February, 2021
                    <br />
                    13.00-15.30
                    </p>
                                    <p className="w-100 text-center">
                                    Intimate wedding for Family only. Please join us virtually
                    </p>
                                    <br />
                                    <h1 className="w-100 text-center">
                                        Intimate Wedding
                    </h1>
                                    <p className="w-100 text-center">
                                        28th February, 2021

                    <br />
                    1st session : 13.00-15.00<br />
                    2nd session : 16.00-18.00
                    </p>
                                    <p className="w-100 text-center">
                                    We hope you will attend to our wedding. Invitation is valid for one person only.
                    </p>
                                </Container>
                                <Container className="py-3">
                                    <Item>
                                        <Col xs={10} md={3}
                                            style={{
                                                border: `2px solid black`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open("https://g.co/kgs/vgHkSF ")
                                            }}
                                            className="btn p-2 mx-md-2">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'black' }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=M3Q4ZW83ZmJ0bDY3ZTlkZmFuZHBzZWEyMXAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                                            xs={10} md={3}
                                            style={{
                                                border: `2px solid black`,
                                                borderRadius: '10px'
                                            }}
                                            className="btn p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'black' }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                    <Container className="py-3">
                                        <Item>
                                            <Col
                                                onClick={() => window.open("https://sepasangberdua.viding.co/")}
                                                xs={11} md={4}
                                                style={{
                                                    border: `2px solid black`,
                                                    borderRadius: '10px'
                                                }}
                                                className="btn p-2 mx-2 mt-3 mt-md-0">
                                                <Item>
                                                    <img src="https://www.flaticon.com/svg/static/icons/svg/3658/3658983.svg" className="img-fluid"
                                                        style={{ width: "10%", height: '10%' }} />
                                                    <p className="mb-0 my-auto ml-3" style={{ color: 'black' }}>
                                                        <b>Live Streaming</b>
                                                    </p>
                                                </Item>
                                            </Col>
                                        </Item>
                                    </Container>

                                </Container>
                                <Container className="text-center dinny">
                                    <Item>
                                        <Col className="py-3 px-sm-5" style={{ border: `2px solid black`, borderRadius: '10px' }}>
                                            <h1 className="fs30"
                                                style={{

                                                    fontFamily: "'Marck Script', cursive",
                                                    color: 'black'
                                                }}>
                                                New Normal Rules
                                                </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                        For safety and comfort, invited guests are expected to comply with the following health regulations:
                                                                </p>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw("asset", "masker.svg")} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Use a Mask
                                                        </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw("asset", "salaman.svg")} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Do not shake hands
                                                                     </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw("asset", "jarak.svg")} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Maintain a distance
                                                                            </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw("asset","cuci.svg")} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                        Always carry your hand sanitizer
                          </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>




                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Item>
                                        <div >
                                            <h1 style={{
                                                color: 'black',
                                                fontStyle: 'bold', fontWeight: 700, fontSize: '72px',
                                                fontFamily: 'Marck Script, cursive'
                                            }}>
                                                The Couple
                        </h1>
                                        </div>
                                    </Item>
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: 'white' }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4' style={{ color: 'black' }}>
                                                        Bersua karena cerita yang sederhana. Satu hal yang kita jaga hanyalah berserah dalam doa kepada-Nya. Karena atas izin-Nya kita satu dan bersama-Nya kita mampu.

                                                    </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={6} sm={2}>
                                                <img src={pw("asset", "bunga-rumput.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: 'rgb(185, 146, 37)'
                                                    }}>
                                                        Send Your Wishes
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Accept </label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Decline</label>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: 'black', color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>


                                <Container className="py-3" fluid >
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid black`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'black' }}>
                                                    <b>Send Gift (click here)</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container fluid
                                    className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >

                                    <Col className="py-3 px-md-5 mx-2 w-100 text-center" style={{ backgroundColor: 'white', borderRadius: '10px', color: '#86835dff' }}>
                                        <h1 style={{ color: 'black', fontFamily: 'Marck Script, cursive', fontSize: '48px' }} className="w-100 text-center">
                                            Wedding Gift
                      </h1>
                                        <p className="text-center w-100" style={{ color: 'black' }}>
                                            BCA : 094-140-2347 <br />
                                        an Ismi Puspita
                          </p>
                                        <Item>
                                            <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>
                                        <Item>
                                            <CopyToClipboard text="094-140-2347">
                                                <Col xs={8} className='btn button mb-5 mt-1'

                                                    style={{ backgroundColor: 'grey', color: 'white' }}
                                                    onClick={(e) => {
                                                        this.setState({ show: true })
                                                    }}>
                                                    Salin No. Rekening
                          </Col>
                                            </CopyToClipboard>
                                        </Item>

                                    </Col>


                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

